@layer tailwind-base, antd;

@layer tailwind-base {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;

.slick-arrow {
  /*position: relative;*/
  z-index: 99999;
}
